:root {
    --font-primary: "Verdana", "Geneva", sans-serif;
    --font-headers: "Oswald", "Open Sans";
    --header-nav-bg: #e5e5e5;
}
html { box-sizing: border-box; }
*, *:before, *:after { box-sizing: inherit; }
  
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
}
  
  h1, h2, h3 {
    margin: 0;
  }
  
  /* 
  * { outline: 1px solid red; }
  */
  /* 
  * { background-color: rgba(255,0,0,.2); }
  * * { background-color: rgba(0,255,0,.2); }
  * * * { background-color: rgba(0,0,255,.2); }
  * * * * { background-color: rgba(255,0,255,.2); }
  * * * * * { background-color: rgba(0,255,255,.2); }
  * * * * * * { background-color: rgba(255,255,0,.2); }
  * * * * * * * { background-color: rgba(255,0,0,.2); }
  * * * * * * * * { background-color: rgba(0,255,0,.2); }
  * * * * * * * * * { background-color: rgba(0,0,255,.2); }
  
 */



.app {
    text-align: center;
}


.app__header {
    background-color: #282c34;
    min-height: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-size: calc(10px + 2vmin);
    color: white;
    flex: 1;
}

.header__header {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}

.header__menu__nav {
}

.app__title {
    padding-top: .5em;
    font-size: .75em;
}

.header__menu__social a {
    color: orange;
    display: inline-block;
}

.header__title {
    padding-bottom: .25em;
}



.main__content__container {
    /* height: 80vh; */
    background: #eaeaea;
    color: darkslategray;
    padding: 2em;

    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
}