.card {
    border-radius: 1.5em;
    text-shadow: 0px -2px #eaeaea;

    transition: background-color 800ms linear;
}

.submit__button {
    background: purple;
    color: white;
    font-size: 2em;

    height: 15vh;
    width: 50vw;

    display: inline-block;
    text-align: auto;
    width: auto;
}

.results__content {
    padding-bottom: 2em;
}